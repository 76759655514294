export const CLICK2PAY_VIEW = {
  notRecognized: 'NOT_RECOGNIZED',
  loginForm: 'LOGIN_FORM',
  codeForm: 'CODE_FORM',
  cardSelected: 'CARD_SELECTED',
  cardSelect: 'CARD_SELECT',
  newCard: 'NEW_CARD',
  newUser: 'NEW_USER',
  dcc: 'DCC'
}

export const C2P_MASTERCARD_IDENTITY_TYPE = {
  email: 'EMAIL_ADDRESS',
  phone: 'MOBILE_PHONE_NUMBER'
}

export const C2P_VISA_IDENTITY_TYPE = {
  email: 'EMAIL',
  phone: 'MOBILE_NUMBER',
  customIdentifier: 'CUSTOM_IDENTIFIER'
}

export const CLICK2PAY_CARD_STATUS = {
  active: 'ACTIVE',
  pending: 'PENDING'
}

export const COUNTRY_CODES = [
  { name: 'Polska', code: '48' },
  { name: 'Afganistan', code: '93' },
  { name: 'Alaska', code: '1907' },
  { name: 'Albania', code: '355' },
  { name: 'Algieria', code: '213' },
  { name: 'Andora', code: '376' },
  { name: 'Angola', code: '244' },
  { name: 'Antyle Holenderskie', code: '599' },
  { name: 'Arabia Saudyjska', code: '966' },
  { name: 'Argentyna', code: '54' },
  { name: 'Armenia', code: '374' },
  { name: 'Australia', code: '61' },
  { name: 'Austria', code: '43' },
  { name: 'Azerbejdżan', code: '994' },
  { name: 'Bahrajn', code: '973' },
  { name: 'Bangladesz', code: '880' },
  { name: 'Belgia', code: '32' },
  { name: 'Benin', code: '229' },
  { name: 'Białoruś', code: '375' },
  { name: 'Boliwia', code: '591' },
  { name: 'Bośnia i Hercegowina', code: '387' },
  { name: 'Botswana', code: '267' },
  { name: 'Brazylia', code: '55' },
  { name: 'Brunei', code: '673' },
  { name: 'Bułgaria', code: '359' },
  { name: 'Burkina Faso', code: '226' },
  { name: 'Burundi', code: '257' },
  { name: 'Chile', code: '56' },
  { name: 'Chiny', code: '86' },
  { name: 'Chorwacja', code: '385' },
  { name: 'Cook\'a Wyspy', code: '682' },
  { name: 'Cypr', code: '357' },
  { name: 'Czad', code: '235' },
  { name: 'Czechy', code: '420' },
  { name: 'Dania', code: '45' },
  { name: 'Diego Garcia', code: '246' },
  { name: 'Dżibuti', code: '253' },
  { name: 'Egipt', code: '20' },
  { name: 'Ekwador', code: '593' },
  { name: 'Erytrea', code: '291' },
  { name: 'Estonia', code: '372' },
  { name: 'Etiopia', code: '251' },
  { name: 'Falklandy', code: '500' },
  { name: 'Fidżi', code: '679' },
  { name: 'Filipiny', code: '63' },
  { name: 'Finlandia', code: '358' },
  { name: 'Francja', code: '33' },
  { name: 'Gabon', code: '241' },
  { name: 'Gambia', code: '220' },
  { name: 'Ghana', code: '233' },
  { name: 'Gibraltar', code: '350' },
  { name: 'Grecja', code: '30' },
  { name: 'Grenlandia', code: '299' },
  { name: 'Gruzja', code: '995' },
  { name: 'Guam', code: '1671' },
  { name: 'Gujana', code: '592' },
  { name: 'Gujana Francuska', code: '594' },
  { name: 'Gwinea', code: '224' },
  { name: 'Gwinea Równikowa', code: '240' },
  { name: 'Gwinea - Bissau', code: '245' },
  { name: 'Hawaje', code: '1808' },
  { name: 'Hiszpania', code: '34' },
  { name: 'Holandia', code: '31' },
  { name: 'Hong Kong', code: '852' },
  { name: 'Indie', code: '91' },
  { name: 'Indonezja', code: '62' },
  { name: 'Irak', code: '964' },
  { name: 'Iran', code: '98' },
  { name: 'Irlandia', code: '353' },
  { name: 'Islandia', code: '354' },
  { name: 'Izrael', code: '972' },
  { name: 'Japonia', code: '81' },
  { name: 'Jemen', code: '967' },
  { name: 'Jordania', code: '962' },
  { name: 'Jugosławia', code: '381' },
  { name: 'Kambodża', code: '588' },
  { name: 'Kamerun', code: '237' },
  { name: 'Kanada', code: '1' },
  { name: 'Kanaryjskie Wyspy', code: '34' },
  { name: 'Katar', code: '974' },
  { name: 'Kazachstan', code: '7' },
  { name: 'Kenia', code: '254' },
  { name: 'Kirgistan', code: '996' },
  { name: 'Kiribati', code: '686' },
  { name: 'Kolumbia', code: '57' },
  { name: 'Komory', code: '269' },
  { name: 'Kongo', code: '242' },
  { name: 'Kongo Republika Demokrat.', code: '234' },
  { name: 'Korea Południowa', code: '82' },
  { name: 'Koreańska RL-D', code: '850' },
  { name: 'Kostaryka', code: '506' },
  { name: 'Kuba', code: '53' },
  { name: 'Kuwejt', code: '965' },
  { name: 'Laos', code: '856' },
  { name: 'Lesotho', code: '266' },
  { name: 'Liban', code: '961' },
  { name: 'Liberia', code: '231' },
  { name: 'Libia', code: '218' },
  { name: 'Liechtenstein', code: '423' },
  { name: 'Litwa', code: '370' },
  { name: 'Luksemburg', code: '352' },
  { name: 'Łotwa', code: '371' },
  { name: 'Macedonia', code: '389' },
  { name: 'Madagaskar', code: '261' },
  { name: 'Makau', code: '853' },
  { name: 'Malawi', code: '265' },
  { name: 'Malediwy', code: '960' },
  { name: 'Malezja', code: '60' },
  { name: 'Mali', code: '223' },
  { name: 'Malta', code: '356' },
  { name: 'Mariany Północne (Saipan)', code: '1670' },
  { name: 'Maroko', code: '212' },
  { name: 'Marshalla Wyspy', code: '692' },
  { name: 'Mauretania', code: '222' },
  { name: 'Mauritius', code: '230' },
  { name: 'Meksyk', code: '52' },
  { name: 'Mołdawia', code: '373' },
  { name: 'Monako', code: '377' },
  { name: 'Mongolia', code: '976' },
  { name: 'Mozambik', code: '258' },
  { name: 'Myanmar (Birma)', code: '95' },
  { name: 'Namibia', code: '264' },
  { name: 'Nauru', code: '674' },
  { name: 'Nepal', code: '977' },
  { name: 'Niemcy', code: '49' },
  { name: 'Niger', code: '227' },
  { name: 'Nigeria', code: '234' },
  { name: 'Nikaragua', code: '505' },
  { name: 'Niue', code: '683' },
  { name: 'Norfolk Wyspa', code: '672' },
  { name: 'Norwegia', code: '47' },
  { name: 'Nowa Kaledonia', code: '687' },
  { name: 'Nowa Zelandia', code: '64' },
  { name: 'Oman', code: '968' },
  { name: 'Owcze Wyspy', code: '298' },
  { name: 'Pakistan', code: '92' },
  { name: 'Palau', code: '680' },
  { name: 'Palestyna', code: '970' },
  { name: 'Panama', code: '507' },
  { name: 'Papua Nowa Gwinea', code: '675' },
  { name: 'Paragwaj', code: '595' },
  { name: 'Peru', code: '51' },
  { name: 'Polinezja Francuska', code: '689' },
  { name: 'Portugalia', code: '351' },
  { name: 'Portoryko', code: '1787' },
  { name: 'Republika Południowej Afryki', code: '27' },
  { name: 'Republika Środkowoafrykańska', code: '236' },
  { name: 'Reunion', code: '262' },
  { name: 'Rosja', code: '7' },
  { name: 'Rumunia', code: '40' },
  { name: 'Rwanda', code: '250' },
  { name: 'Sain Christopher i Nevis', code: '1869' },
  { name: 'Saint Lucia', code: '1758' },
  { name: 'Saint Vincent', code: '1809' },
  { name: 'Salomona Wyspy', code: '677' },
  { name: 'Salwador', code: '503' },
  { name: 'Samoa', code: '684' },
  { name: 'Samoa Zachodnie', code: '685' },
  { name: 'San Marino', code: '378' },
  { name: 'Senegal', code: '221' },
  { name: 'Seszele', code: '248' },
  { name: 'Sierra Leone', code: '232' },
  { name: 'Singapur', code: '65' },
  { name: 'Słowacja', code: '421' },
  { name: 'Słowenia', code: '386' },
  { name: 'Somalia', code: '252' },
  { name: 'Sri Lanka', code: '94' },
  { name: 'Stany Zjednoczone Ameryki', code: '1' },
  { name: 'Suazi', code: '268' },
  { name: 'Surinam', code: '597' },
  { name: 'Syria', code: '963' },
  { name: 'Szwajcaria', code: '41' },
  { name: 'Szwecja', code: '46' },
  { name: 'Św. Heleny Wyspa', code: '290' },
  { name: 'Św. Piotra i Mikeleona Wyspy', code: '508' },
  { name: 'Św. Tomasza Wyspy', code: '239' },
  { name: 'Tadżykistan', code: '992' },
  { name: 'Tajlandia', code: '66' },
  { name: 'Tajwan', code: '886' },
  { name: 'Tanzania', code: '255' },
  { name: 'Togo', code: '228' },
  { name: 'Tokelau', code: '690' },
  { name: 'Tonga', code: '676' },
  { name: 'Tunezja', code: '216' },
  { name: 'Turcja', code: '90' },
  { name: 'Turkmenistan', code: '993' },
  { name: 'Tursk (wyspy)', code: '1649' },
  { name: 'Tuvalu', code: '688' },
  { name: 'Uganda', code: '256' },
  { name: 'Ukraina', code: '380' },
  { name: 'Urugwaj', code: '598' },
  { name: 'Uzbekistan', code: '998' },
  { name: 'Vanuatu', code: '678' },
  { name: 'Wallis i Futuna', code: '681' },
  { name: 'Watykan', code: '3906' },
  { name: 'Wenezuela', code: '58' },
  { name: 'Węgry', code: '36' },
  { name: 'Wielka Brytania', code: '44' },
  { name: 'Wietnam', code: '84' },
  { name: 'Włochy', code: '39' },
  { name: 'Wniebowstąpienia Wyspa', code: '247' },
  { name: 'Wybrzeże Kości Słoniowej', code: '225' },
  { name: 'Zambia', code: '260' },
  { name: 'Zanzibar', code: '259' },
  { name: 'Zielonego Przylądka Wyspy', code: '238' },
  { name: 'Zimbabwe', code: '263' }
]

export const CLICK2PAY_MC_ERROR_MESSAGES = {
  emailLocked: 'Error: Access is denied to the requested resource. The user account has been locked.',
  emailNotRecognized: 'Error: Email not registered.',
  codeNotValid: 'Error: Client specified an invalid argument. Check error message and error details for more information.',
  codeAccountLocked: 'Error: Access is denied to the requested resource. The user account has been locked.'
}

export const CLICK2PAY_VISA_ERROR_REASON = {
  codeInvalid: 'CODE_INVALID',
  acctInaccessible: 'ACCT_INACCESSIBLE'
}

export const CLICK2PAY_LOG_LEVEL = {
  info: 'INFO',
  error: 'ERROR'
}

export const CLICK2PAY_ORGANIZATION = {
  visa: 'VISA',
  mastercard: 'MASTERCARD'
}

export const C2P_VISA_TERMS_URL = 'https://www.visa.com/{locale}/checkout/legal/terms-of-service.html'
export const C2P_VISA_PRIVACY_URL = 'https://www.visa.com/{locale}/checkout/legal/global-privacy-notice.html'
export const C2P_VISA_COOKIE_URL = 'https://www.visa.com/{locale}/checkout/legal/global-privacy-notice/cookie-notice.html'
