<template>
  <div class="form-row">
    <click-to-pay-profile />
    <div>
      <div v-for="(card, index) in cards" :key="index" class="c2p-card" :class="{'selected': isCardSelected(card)}">
        <div>
          <img :src="getCardImg(card)" height="40" alt="">
          <span>**** {{ getLast4Digits(card) }}</span>
        </div>
        <span>{{ $t('paywall.form.click2pay.cardSelected.date') }} {{ getExpirationDate(card) }}</span>
        <button type="button" v-if="isCardSelected(card)" class="btn btn-plain"><strong>{{ $t('paywall.form.click2pay.cardSelect.selected') }}</strong></button>
        <button type="button" v-if="!isCardSelected(card)" @click="selectCard(card)" class="btn btn-plain">{{ $t('paywall.form.click2pay.cardSelect.select') }}</button>
      </div>
    </div>
    <div class="c2p-new-card">
      <button type="button" @click="goToClick2PayNewCard" class="btn btn-plain">{{ $t('paywall.form.click2pay.cardSelect.payOther') }}</button>
    </div>
    <div class="c2p-back">
      <button type="button" @click="goToClick2PayCardSelected" class="btn btn-plain">{{ $t('paywall.form.click2pay.cardSelect.back') }}</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import click2payMixin from '@/base/mixins/click2pay.mixin'
import { CLICK2PAY_VIEW } from '@/base/const/click2pay.const'
import ClickToPayProfile from '@/base/components/Payment/Paywall/ClickToPay/Profile.vue'

export default {
  name: 'ClickToPayCardSelect',
  components: { ClickToPayProfile },
  mixins: [click2payMixin],
  computed: {
    ...mapGetters({
      cards: 'getC2PCards'
    })
  },
  methods: {
    ...mapMutations([
      'SET_C2P_VIEW'
    ]),
    ...mapActions([
      'setC2PSelectedCard'
    ]),
    isCardSelected (card) {
      if (this.selectedCard) {
        return card.srcDigitalCardId === this.selectedCard.srcDigitalCardId
      }
      return false
    },
    selectCard (card) {
      this.setC2PSelectedCard(card)
      this.goToClick2PayCardSelected()
    },
    goToClick2PayNewCard () {
      this.setC2PSelectedCard(null)
      this.SET_C2P_VIEW(CLICK2PAY_VIEW.newCard)
    },
    goToClick2PayCardSelected () {
      this.SET_C2P_VIEW(CLICK2PAY_VIEW.cardSelected)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/PaywallForm.scss";
button {
  font-size: 12px;
}

.c2p-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  font-size: 16px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &.selected {
    border: 1px solid var(--color-primary);
  }
  & > div {
    display: flex;
    align-items: center;
  }
  img {
    margin-right: 8px;
  }
}

.c2p-new-card {
  margin-top: 20px;
}
.c2p-back {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.c2p-card,
.c2p-new-card {
  button {
    &.btn.btn-plain {
      text-transform: unset;
    }
  }
}
</style>
