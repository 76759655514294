<template>
  <div class="payment-type-container"
       :class="{'disabled': !group.active}"
       @click="selectPaywayGroup"
       v-if="paywaysLength">
    <div class="payment-type-box"
         :class="{
           'active': isPaywayGroupSelected(),
           'promo': isPaymentTypePromo(),
           'visamobile': group.isVisaMobile,
           'mastercard': group.isMastercard,
           'blik': group.isBlik
         }">
      <div class="payment-type-box-outer" :style="{ height: height }">
        <div ref="paymentTypeBox" class="payment-type-box-inner">
          <div v-if="isPaymentTypePromo()"
               class="promo-notice"
               :class="{
                 'visamobile': group.isVisaMobile,
                 'mastercard': group.isMastercard,
                 'blik': group.isBlik
               }">
            <span v-if="group.isNew">{{ $t('paywall.paywayGroup.notice.novelty') }}</span>
            <span v-if="group.isMastercard && isClick2PayAvailable">{{ $t('paywall.paywayGroup.notice.mastercardC2P') }}</span>
            <span v-if="group.isMastercard && !isClick2PayAvailable">{{ $t('paywall.paywayGroup.notice.mastercard') }}</span>
            <span v-if="group.isBlik">{{ $t('paywall.paywayGroup.notice.blik') }}</span>
            <span v-if="group.isVisaMobile">{{ $t('paywall.paywayGroup.notice.visaMobile') }}</span>
            <span v-if="!group.isNew && !group.isMastercard && !group.isBlik && !group.isVisaMobile">{{ $t('paywall.paywayGroup.notice.recommended') }}</span>
          </div>
          <div class="heading"
               role="radio"
               name="payway-radio-group"
               :aria-checked="isPaywayGroupSelected()"
               :tabindex="0"
               :id="groupId"
               :label="group.label"
               @keyup.32="selectPaywayGroup">
            <div class="top">
              <div class="checkbox-container" v-if="canShowPaywayGroupRadio">
                <div class="checkbox"></div>
              </div>
              <div class="inner">
                <div class="text-and-logo">
                  <div class="text">
                    <h2 class="payment-type-title" v-html="getPaywayGroupLabel(group)"></h2>
                  </div>
                  <div class="logo">
                    <div v-if="isPaymentTypePromo()">
                      <div v-if="group.isBlik" class="blik">
                        <span class="ico"></span>
                      </div>
                      <div v-if="group.isMastercard" class="cards">
                        <span class="ico" :class="{'click2pay': isClick2PayAvailable}"></span>
                      </div>
                      <div v-if="!group.isBlik && !group.isMastercard" class="promo">
                        <img :src="group.iconUrl" alt="promo-img" />
                      </div>
                    </div>
                    <div v-if="isPaymentTypeBlik()" class="blik">
                      <span class="ico"></span>
                    </div>
                    <div v-if="isPaymentTypeCard()" class="cards">
                      <span class="ico" :class="{'click2pay': isClick2PayAvailable}"></span>
                    </div>
                    <div v-if="isPaymentTypePbl()" class="pbl">
                      <span class="number">{{ getPblNumberText(paywaysLength) }}</span>
                      <logo-slider :logo-images="logoList" v-if="paywaysLength > 0" />
                      <span v-else class="ico"></span>
                    </div>
                    <div v-if="isPaymentTypeWallet()" class="wallet">
                      <span class="number">{{ getWalletNumberText(paywaysLength) }}</span>
                      <logo-slider :logo-images="logoList" v-if="paywaysLength > 0" />
                      <span v-else class="ico"></span>
                    </div>
                    <div v-if="isPaymentTypeVisaMobile()" class="visaMobile">
                      <span class="ico"></span>
                    </div>
                    <div v-if="isPaymentTypeInstallments()" class="installment">
                      <span class="number">{{ getInstallmentsNumberText(paywaysLength) }}</span>
                      <logo-slider :logo-images="logoList" v-if="paywaysLength > 0" />
                      <span v-else class="ico"></span>
                    </div>
                    <div v-if="isPaymentTypeOtp()" class="otp">
                      <span class="number">{{ getOtpNumberText(paywaysLength) }}</span>
                      <logo-slider :logo-images="logoList" v-if="paywaysLength > 0" />
                      <span v-else class="ico"></span>
                    </div>
                    <div v-if="isPaymentTypeBlikPayLater()" class="otp">
                      <span class="ico blikPayLater"></span>
                    </div>
                    <div v-if="isPaymentTypeCompanyPayments()" class="companyPayments">
                      <span class="ico"></span>
                    </div>
                    <div v-if="isPaymentTypeTransfer()" class="transfer">
                      <span class="ico"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom" v-if="!group.active">
              <p>{{ $t('paywall.paywayGroup.inactiveMessage') }}</p>
            </div>
            <div class="bottom" :class="{'is-checked': canShowPaywayGroupRadio}" v-if="(isPaywayGroupSelected() || isPaymentTypePromo()) && canShowGroupDescription() && group.description">
              <p v-html="getPaywayGroupDescription(group)"></p>
            </div>
            <div class="blikPayLaterLink" id="blikHelp" v-if="isPaywayGroupOtpBlikPayLater(group)">
              <a href="" @click.prevent="showBlikPayLaterModal($event)">{{ $t('paywall.paywayGroup.blikPayLater.info') }}</a>
            </div>
          </div>
          <div class="full-data" v-if="isPaywayGroupSelected()">
            <paywall-form :payway-group="group" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { AUTO_ID_LIST, OTP_TYPES, PAYWAY_GROUPS_TYPES, WALLET_TYPES } from '@/base/const/payway.const'
import PaywallForm from '@/base/components/Payment/Paywall/Form'
import { ToolsClass } from '@/base/class/tools.class'
import LogoSlider from '@/base/components/LogoSlider/LogoSlider'
import { PAYMENT_INIT_TYPE, PAYMENT_SERVICE_TYPE } from '@/base/const/payment.const'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import { INSTALLMENTS_IMAGES, OTP_IMAGES, PBL_IMAGES, WALLET_IMAGES } from '@/base/const/logoSlides.const'
import { PAYWALL_MODE } from '@/base/const/paywall.const'
import Vue from 'vue'
import { ORANGE_CONTEXT_LIST } from '@/base/const/context.const'
import { MESSAGES } from '@/base/const/texts.const'

const autoHeightKey = 'auto'
const eventName = 'paywallGroupExpand'
const contextServiceVerification = 'service-verification'
export default {
  name: 'PaymentPaywayGroup',
  data () {
    return {
      height: autoHeightKey
    }
  },
  components: {
    LogoSlider,
    PaywallForm
  },
  props: {
    group: {
      type: Object
    },
    groupId: {
      type: String
    }
  },
  watch: {
    selectedPaywayGroupId: function (value, oldValue) {
      if (value !== oldValue && this.isPaywayGroupSelected(this.group.id)) {
        this.selectPayment()
      }
    }
  },
  computed: {
    ...mapState({
      isClick2PayAvailable: state => state.c2p.isProcessAvailable,
      isAuto: state => AUTO_ID_LIST.includes(state.payment.form.paywayID),
      selectedPaywayGroupId: state => state.payment.selectedPaywayGroupId,
      isServiceTypeMwf: state => state.payment.paymentData.serviceType === PAYMENT_SERVICE_TYPE.mwf,
      isServiceTypeEcommerce: state => state.payment.paymentData.serviceType === PAYMENT_SERVICE_TYPE.ecommerce,
      recurringAction: state => state.payment.paymentData.recurringAction,
      isServiceTypeVerification: state => [PAYMENT_SERVICE_TYPE.verificationBank, PAYMENT_SERVICE_TYPE.verificationNotBank].includes(state.payment.paymentData.serviceType),
      isContextServiceVerification: state => state.payment.contextName === contextServiceVerification,
      canShowPaywayGroupRadio: state => state.payment.paywallMode === PAYWALL_MODE.main,
      lastPayway: state => state.payment.paymentData.lastUsedPayway
    }),
    paywaysLength: function () {
      return this.group.payways.length
    },
    logoList: function () {
      let images = []
      switch (this.group.type) {
        case PAYWAY_GROUPS_TYPES.wallet:
          images = WALLET_IMAGES
          break
        case PAYWAY_GROUPS_TYPES.pbl:
          images = PBL_IMAGES
          break
        case PAYWAY_GROUPS_TYPES.otp:
          images = OTP_IMAGES
          break
        case PAYWAY_GROUPS_TYPES.installments:
          images = INSTALLMENTS_IMAGES
          break
      }
      return images.filter(image => this.group.payways.some(payway => image.id === payway.id)).map(item => item.img)
    }
  },
  methods: {
    ...mapMutations([
      'SET_PAYMENT_TRANSACTION_PAYWAY_GROUPS'
    ]),
    ...mapActions([
      'setPaymentGroupSelected',
      'setPaymentActivePayway',
      'addModalInfo'
    ]),
    getPaywayGroupLabel (group) {
      if (this.isAuto) {
        if (this.recurringAction === PAYMENT_INIT_TYPE.initWithRefund) {
          if (ORANGE_CONTEXT_LIST.includes(this.$VUE_CONTEXT_NAME)) {
            return group.labelInitWithRefundOrange
          }
          return group.labelInitWithRefund
        }
        if (this.recurringAction === PAYMENT_INIT_TYPE.initWithPayment) {
          return group.labelInitWithPayment
        }
      }
      return group.label
    },
    isPaywayGroupOtpBlikPayLater (group) {
      return group.payways[0].id === OTP_TYPES.blikPayLater.id
    },
    showBlikPayLaterModal (event) {
      event.stopPropagation()
      const message = {
        focusReturnElementId: 'blikHelp',
        component: 'BlikPayLaterModal',
        options: {
          size: 'big',
          hideButton: true,
          showClose: true
        }
      }
      this.addModalInfo(message)
    },
    getPaywayGroupDescription (group) {
      if (this.isPaymentTypePbl() && this.isServiceTypeVerification) {
        return group.descriptionVerify
      }
      if (this.isPaymentTypePbl() && this.isContextServiceVerification) {
        return group.descriptionServiceVerify
      }
      if (this.isAuto) {
        if (this.recurringAction === PAYMENT_INIT_TYPE.initWithRefund) {
          return group.descriptionInitWithRefund
        }
        if (this.recurringAction === PAYMENT_INIT_TYPE.initWithPayment) {
          return group.descriptionInitWithPayment
        }
      }
      return group.description
    },
    getPblNumberText (number) {
      const words = [
        this.$t('paywall.paywayGroup.bank.many'),
        this.$t('paywall.paywayGroup.bank.one'),
        this.$t('paywall.paywayGroup.bank.two')
      ]
      return ToolsClass.pluralize(number, words)
    },
    getOtpNumberText (number) {
      const words = [
        this.$t('paywall.paywayGroup.otp.many'),
        this.$t('paywall.paywayGroup.otp.one'),
        this.$t('paywall.paywayGroup.otp.two')
      ]
      return ToolsClass.pluralize(number, words)
    },
    getWalletNumberText (number) {
      const words = [
        this.$t('paywall.paywayGroup.wallet.many'),
        this.$t('paywall.paywayGroup.wallet.one'),
        this.$t('paywall.paywayGroup.wallet.two')
      ]
      return ToolsClass.pluralize(number, words)
    },
    getInstallmentsNumberText (number) {
      const words = [
        this.$t('paywall.paywayGroup.bank.many'),
        this.$t('paywall.paywayGroup.bank.one'),
        this.$t('paywall.paywayGroup.bank.two')
      ]
      return ToolsClass.pluralize(number, words)
    },
    isPaywayGroupSelected () {
      return this.selectedPaywayGroupId !== null && this.group.id === this.selectedPaywayGroupId
    },
    isSinglePaywayInGroup () {
      return this.group.payways.length === 1
    },
    selectPaywayGroup () {
      if (!this.group.active) {
        const message = {
          text: MESSAGES({ groupName: this.group.label }).errors.groupInactive,
          focusReturnElementId: this.groupId
        }
        this.addModalInfo(message)
        return false
      }
      if (this.isPaywayGroupSelected(this.group.id)) {
        return
      }
      this.setPaymentGroupSelected(this.group)
        .finally(() => {
          window.dispatchEvent(new CustomEvent(eventName))
          if (this.isPaymentTypeWallet()) {
            const gPay = this.group.payways.find(payway => payway.id === WALLET_TYPES.googlePay.id)
            if (gPay && gPay.active && ToolsClass.isWalletPaywayAvailable(gPay.id)) {
              this.setPaymentActivePayway(gPay)
                .then(() => {
                  setTimeout(() => {
                    ToolsClass.scrollToElementTop(document.querySelector('.payment-type-box.active'), -20)
                  }, 250)
                })
                .catch(() => {})
            }
          }
        })
    },
    selectPayment () {
      let payway = null
      if (this.isSinglePaywayInGroup()) {
        payway = this.group.payways[0]
        if (window.yourcx) {
          const paywayId = payway.id
          const paywayName = payway.nameOnList
          window.yourcx('dimension', 'payment.method', paywayId, 'session')
          window.yourcx('dimension', 'payment.method_name', paywayName, 'session')
        }
      }
      this.setPaymentActivePayway(payway)
        .then(() => {
          setTimeout(() => {
            ToolsClass.scrollToElementTop(document.querySelector('.payment-type-box.active'), -20)
          }, 250)
        })
        .catch(() => {})
    },
    canShowGroupDescription () {
      return !Vue.prototype.$VUE_USE_APP_BLUEMEDIA
    },
    isPaymentTypePromo () {
      return this.group.type === PAYWAY_GROUPS_TYPES.promo
    },
    isPaymentTypeAuto () {
      return this.group.type === PAYWAY_GROUPS_TYPES.auto
    },
    isPaymentTypeBlik () {
      return this.group.type === PAYWAY_GROUPS_TYPES.blik
    },
    isPaymentTypeCard () {
      return [PAYWAY_GROUPS_TYPES.card, PAYWAY_GROUPS_TYPES.auto].includes(this.group.type)
    },
    isPaymentTypePbl () {
      return this.group.type === PAYWAY_GROUPS_TYPES.pbl
    },
    isPaymentTypeWallet () {
      return this.group.type === PAYWAY_GROUPS_TYPES.wallet
    },
    isPaymentTypeInstallments () {
      return this.group.type === PAYWAY_GROUPS_TYPES.installments
    },
    isPaymentTypeOtp () {
      return this.group.type === PAYWAY_GROUPS_TYPES.otp
    },
    isPaymentTypeBlikPayLater () {
      return this.group.type === PAYWAY_GROUPS_TYPES.blikPayLater
    },
    isPaymentTypeVisaMobile () {
      return this.group.type === PAYWAY_GROUPS_TYPES.visaMobile
    },
    isPaymentTypeCompanyPayments () {
      return this.group.type === PAYWAY_GROUPS_TYPES.companyPayments
    },
    isPaymentTypeTransfer () {
      return this.group.type === PAYWAY_GROUPS_TYPES.transfer
    },
    setGroupHeight () {
      if (this.$refs.paymentTypeBox) {
        const currentHeight = this.$refs.paymentTypeBox.scrollHeight
        if (!this.isPaywayGroupSelected() && currentHeight < 150) {
          return
        }
        this.height = currentHeight + 'px'
        setTimeout(() => {
          if (this.$refs.paymentTypeBox) {
            this.height = this.$refs.paymentTypeBox.scrollHeight + 'px'
          }
        })
        setTimeout(() => {
          this.height = autoHeightKey
        }, 300)
      }
    }
  },
  mounted () {
    MobileAppClass.supportExternalLinks()
    if (this.lastPayway && this.group.payways.find(payway => payway.id === this.lastPayway.id) && this.group.active) {
      this.selectPaywayGroup(this.group)
      this.selectPayment()
    }
    if (this.isPaymentTypePromo() && this.group.promoColour) {
      document.documentElement.style.setProperty('--payway-promo-bg', this.group.promoColour)
    }
    window.addEventListener(eventName, this.setGroupHeight)
  },
  updated () {
    this.$nextTick(function () {
      MobileAppClass.supportExternalLinks()
    })
  },
  destroyed () {
    window.removeEventListener(eventName, this.setGroupHeight)
  }
}
</script>

<style lang="scss" scoped>
body {
  &.using-keyboard {
    .heading:focus {
      box-shadow: 0 0 0 0;
      .checkbox-container {
        border-radius: 0;
        box-shadow: 0 0 0 2px black;
      }
    }
  }
}
</style>
