import { ToolsClass } from '@/base/class/tools.class'
import {
  MATOMO_EVENT_CATEGORY,
  MATOMO_EVENT_TYPE,
  MATOMO_PAYMENT_TYPE_GROUP,
  MATOMO_VALIDATION_ERROR_NAME
} from '@/base/const/matomo.const'
import { MatomoClass } from '@/base/class/matomo.class'
import { WidgetConnection, widgetEvents } from '@bm/widget-connection'
import Vue from 'vue'
import { PAYWALL_THEME } from '@/base/const/paywall.const'
import { mapState } from 'vuex'
import { PAYMENT_INIT_TYPE } from '@/base/const/payment.const'
import { CARD_ISSUERS } from '@/base/const/cards.const'
import { DateClass } from '@/base/class/date.class'
import { CookieClass } from '@/base/class/cookie.class'
import { COOKIE_DCC } from '@/base/const/cookies.const'

export default {
  beforeDestroy () {
    if (this.widget) {
      this.widget.stopConnection()
    }
  },
  computed: {
    ...mapState({
      isPaywallThemeDark: state => state.payment.paywallTheme === PAYWALL_THEME.dark
    })
  },
  methods: {
    getCardIframeSrc () {
      let urlParams = '?ver=B'
      if (this.isPaywallThemeDark) {
        urlParams = urlParams + '&darkMode=true'
      }
      if (Vue.prototype.$VUE_CARD_IFRAME_URL) {
        return Vue.prototype.$VUE_CARD_IFRAME_URL + urlParams
      }
      return process.env.VUE_APP_WIDGET_NEW_URL + urlParams
    },
    saveDccChangeInfoInCookie ({ amount, currency, rate, marginPercentage }) {
      if (!amount || !currency) {
        return
      }
      const dccInfo = {
        timestamp: DateClass.getCurrentTimestamp(),
        idTransRemote: this.idTransRemote,
        orderId: this.orderId,
        serviceId: this.serviceID,
        transaction: {
          amount: this.amount,
          currency: this.currency
        },
        dcc: {
          amount: amount,
          currency: currency,
          rate: rate,
          marginPercentage: marginPercentage
        }
      }
      let cookieDccList = CookieClass.getCookie(COOKIE_DCC)
      if (cookieDccList && cookieDccList.length) {
        cookieDccList = JSON.parse(cookieDccList)
        if (!Array.isArray(cookieDccList)) {
          cookieDccList = []
        }
      } else {
        cookieDccList = []
      }
      cookieDccList.push(dccInfo)
      CookieClass.setCookie(COOKIE_DCC, cookieDccList, 7, '.autopay.eu')
    },
    setCardIframeLoaded () {
      const widgetConfig = {
        serviceId: this.serviceID,
        amount: this.amount,
        currency: this.currency,
        recurringAction: this.getRecurringActionValue(),
        idTransRemote: this.idTransRemote
      }
      this.widget = new WidgetConnection(widgetConfig, ToolsClass.isDebugEnabled())
      this.widget.startConnection(this.$refs.iframe).then(() => {
        this.iframeLoaded = true
        this.isCreditCardMastercard = false
        this.isCreditCardVisa = false
        this.widget.on(widgetEvents.validityStatus, (message, data) => {
          const result = message ? Object.keys(message).every(key => message[key].valid) : data.valid
          this.setCreditCardError(result)
        })

        this.widget.on(widgetEvents.validationResult, (message, data) => {
          const result = message ? message.valid : data.valid
          this.setCreditCardError(result, true)
        })

        this.widget.on(widgetEvents.formSuccess, (message) => {
          this.sendToken(message)
        })

        this.widget.on(widgetEvents.enterKey, () => {
          this.submit()
        })

        this.widget.on(widgetEvents.cardRecognized, (message, data) => {
          this.isCreditCardMastercard = message && message.toUpperCase() === CARD_ISSUERS.mastercard
          this.isCreditCardVisa = message && message.toUpperCase() === CARD_ISSUERS.visa
        })

        this.widget.on(widgetEvents.encryptedCard, (message, data) => {
          this.submitClick2PayEncryptedCard(message)
        })

        this.widget.on(widgetEvents.dccChange, (message, data) => {
          this.saveDccChangeInfoInCookie(message)
          this.changeTransactionDataByDcc(message)
        })

        this.widget.on(widgetEvents.viewChange, (message, data) => {
          this.resolveCheckDccForSelectedCard(message)
        })

        this.widget.on(widgetEvents.checkoutDccDone, (message, data) => {
          this.submitClick2PayCheckout()
        })
      })
      this.widget.changeLanguage(this.lang)
    },
    setCreditCardError (valid, sendEvent) {
      if (!valid) {
        if (this.creditCard) {
          this.creditCard = false
          if (this.form.invalid) {
            this.scrollToError()
          }
        }
        if (sendEvent) {
          const event = [
            MATOMO_EVENT_TYPE.trackEvent,
            MATOMO_EVENT_CATEGORY.validationError,
            MATOMO_PAYMENT_TYPE_GROUP[this.selectedPaywayGroupType],
            MATOMO_VALIDATION_ERROR_NAME.cardData
          ]
          MatomoClass.sendEvent(event)
        }
      } else {
        if (!this.creditCard) {
          this.creditCard = true
        }
      }
    },
    getRecurringActionValue () {
      if (!this.activePayway.recurring) {
        return null
      }

      if (this.recurringAction) {
        return this.recurringAction
      }
      return PAYMENT_INIT_TYPE.initWithPayment
    }
  }
}
