import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      selectedCard: state => state.c2p.selectedCard
    })
  },
  methods: {
    getCardImg (card) {
      if (card) {
        return card.digitalCardData.artUri
      }
    },
    getLast4Digits (card) {
      if (card) {
        return card.panLastFour
      }
    },
    getExpirationDate (card) {
      if (card && card.panExpirationMonth && card.panExpirationYear) {
        return `${card.panExpirationMonth}/${card.panExpirationYear.substring(2)}`
      }
      return '__/__'
    }
  }
}
