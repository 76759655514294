<template>
  <form ref="form-userdata" @submit.prevent="submit" v-form:form novalidate>
    <div class="form-row">
      <div>
        <img v-if="isCreditCardMastercard" src="/web/img/click2pay/mastercard.svg" alt="" height="20">
        <img v-if="isCreditCardVisa" src="/web/img/click2pay/visa.svg" alt="" height="20">
      </div>
      <p class="subheader">{{ $t('paywall.form.click2pay.newUserForm.header') }}</p>
      <p>{{ $t('paywall.form.click2pay.newUserForm.description') }}</p>
    </div>
    <div class="form-row input-text-field" :class="{'is-error': isErrorForField('email')}">
      <label for="email">{{ $t('paywall.form.click2pay.newUserForm.emailLabel') }}</label>
      <p role="alert" tabindex="0"
         v-if="isErrorForField('email')"
         class="error"
         :aria-label="getFieldErrorAriaLabel('email')">
        <span aria-hidden="true">{{ getFieldErrorText('email') }}</span>
      </p>
      <div class="input-text-wrapper" :class="{'is-error': isErrorForField('email')}">
        <input
          type="text"
          name="email"
          id="email"
          ref="email"
          :placeholder="$t('paywall.form.click2pay.newUserForm.emailPlaceholder')"
          :readonly="isEmailDisabled()"
          required
          email
          @keydown.13.prevent
          v-model.trim="formData.email"/>
        <span class="input-flow-label">&nbsp;{{ $t('paywall.form.click2pay.newUserForm.emailLabelFlow') }}&nbsp;</span>
        <span class="input-flow-mark"></span>
      </div>
    </div>
    <div class="form-row input-text-field" :class="{'is-error': isErrorForField('country')}">
      <label for="country">{{ $t('paywall.form.click2pay.newUserForm.countryLabel') }}</label>
      <p role="alert" tabindex="0"
         v-if="isErrorForField('country')"
         class="error"
         :aria-label="getFieldErrorAriaLabel('country')">
        <span aria-hidden="true">{{ getFieldErrorText('country') }}</span>
      </p>
      <div class="select-wrapper country">
        <select name="country" id="country" required v-model="formData.country" @input.stop @change="setCountryCode">
          <option v-for="(country, index) in countryList" :key="index" :value="country">{{ country }}</option>
        </select>
      </div>
    </div>
    <div class="userdata">
      <div class="form-row input-text-field" :class="{'is-error': isErrorForField('firstname')}">
        <label for="firstname">{{ $t('paywall.form.click2pay.newUserForm.firstnameLabel') }}</label>
        <p role="alert" tabindex="0"
           v-if="isErrorForField('firstname')"
           class="error"
           :aria-label="getFieldErrorAriaLabel('firstname')">
          <span aria-hidden="true">{{ getFieldErrorText('firstname') }}</span>
        </p>
        <div class="input-text-wrapper" :class="{'is-error': isErrorForField('firstname')}">
          <input
            type="text"
            name="firstname"
            id="firstname"
            :placeholder="$t('paywall.form.click2pay.newUserForm.firstnamePlaceholder')"
            required
            @keydown.13.prevent
            v-model.trim="formData.firstname"/>
          <span class="input-flow-label">&nbsp;{{
              $t('paywall.form.click2pay.newUserForm.firstnameLabelFlow')
            }}&nbsp;</span>
          <span class="input-flow-mark"></span>
        </div>
      </div>
      <div class="form-row input-text-field" :class="{'is-error': isErrorForField('lastname')}">
        <label for="lastname">{{ $t('paywall.form.click2pay.newUserForm.lastnameLabel') }}</label>
        <p role="alert" tabindex="0"
           v-if="isErrorForField('lastname')"
           class="error"
           :aria-label="getFieldErrorAriaLabel('lastname')">
          <span aria-hidden="true">{{ getFieldErrorText('lastname') }}</span>
        </p>
        <div class="input-text-wrapper" :class="{'is-error': isErrorForField('lastname')}">
          <input
            type="text"
            name="lastname"
            id="lastname"
            :placeholder="$t('paywall.form.click2pay.newUserForm.lastnamePlaceholder')"
            required
            @keydown.13.prevent
            v-model.trim="formData.lastname"/>
          <span class="input-flow-label">&nbsp;{{
              $t('paywall.form.click2pay.newUserForm.lastnameLabelFlow')
            }}&nbsp;</span>
          <span class="input-flow-mark"></span>
        </div>
      </div>
    </div>
    <div class="form-row input-text-field" :class="{'is-error': isErrorForField('phone')}">
      <label for="phone">{{ $t('paywall.form.click2pay.newUserForm.phoneLabel') }}</label>
      <p role="alert" tabindex="0"
         v-if="isErrorForField('phone')"
         class="error"
         :aria-label="getFieldErrorAriaLabel('phone')">
        <span aria-hidden="true">{{ getFieldErrorText('phone') }}</span>
      </p>
      <div class="c2p-customer-phone">
        <div class="select-wrapper countryCode">
          <select name="countryCode" id="countryCode" required v-model="formData.countryCode" @input.stop>
            <option v-for="(item, index) in countryCodesList" :key="index" :value="item.code">+{{ item.code }}</option>
          </select>
        </div>
        <div class="input-text-wrapper phoneNumber" :class="{'is-error': isErrorForField('phone')}">
          <input
            type="text"
            name="phone"
            id="phone"
            :placeholder="$t('paywall.form.click2pay.newUserForm.phonePlaceholder')"
            required
            phone
            @keydown.13.prevent
            v-model.trim="formData.phone"/>
          <span class="input-flow-label">&nbsp;{{
              $t('paywall.form.click2pay.newUserForm.phoneLabelFlow')
            }}&nbsp;</span>
          <span class="input-flow-mark"></span>
        </div>
      </div>
    </div>
    <div class="agreements">
      <div class="form-row">
        <p class="terms-clause" v-html="$t('paywall.form.click2pay.newUserForm.c2pInfo')"></p>
      </div>
    </div>
    <div class="agreements">
      <div class="form-row">
        <input type="checkbox"
               id="trusted"
               name="trusted"
               @keydown.13.prevent
               v-model="formData.trusted"/>
        <label for="trusted">
          <span v-html="$t('paywall.form.click2pay.newUserForm.trustedLabel')"></span>
        </label>
      </div>
    </div>
    <div class="agreements">
      <div class="form-row">
        <p v-if="isCreditCardMastercard" class="terms-clause" v-html="$t('paywall.form.click2pay.newUserForm.termsMastercardLabel')"></p>
        <p v-if="isCreditCardVisa" class="terms-clause" v-html="$t('paywall.form.click2pay.newUserForm.termsVisaLabel', {c2pVisaTermsUrl: c2pVisaTermsUrl, c2pVisaPrivacyUrl: c2pVisaPrivacyUrl})"></p>
      </div>
      <div class="form-row" v-if="isCreditCardVisa && formData.trusted">
        <p class="terms-clause" v-html="$t('paywall.form.click2pay.newUserForm.cookieVisaLabel', {c2pVisaCookieUrl: c2pVisaCookieUrl})"></p>
      </div>
    </div>
    <div class="submit-wrapper">
      <div class="c2p-submit">
        <button type="submit" class="btn" :disabled="processing">
          <span>{{ $t('paywall.form.click2pay.newUserForm.buttonSubmit') }}</span>
        </button>
        <button type="button" @click="goBack" class="btn btn-plain">
          {{ $t('paywall.form.click2pay.newUserForm.buttonBack') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import formMixin from '@/base/mixins/form.mixin'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  C2P_VISA_COOKIE_URL,
  C2P_VISA_PRIVACY_URL,
  C2P_VISA_TERMS_URL,
  CLICK2PAY_VIEW,
  COUNTRY_CODES
} from '@/base/const/click2pay.const'
import { CARD_ISSUERS } from '@/base/const/cards.const'
import { LANGUAGES_CODE_LIST } from '@/base/const/lang.const'

export default {
  name: 'ClickToPayNewUser',
  mixins: [formMixin],
  data () {
    return {
      formData: {
        email: null,
        firstname: null,
        lastname: null,
        phone: null,
        country: null,
        countryCode: null,
        trusted: false
      }
    }
  },
  computed: {
    ...mapState({
      processing: state => state.c2p.processing,
      customerEmail: state => state.payment.form.customerEmail,
      c2pEmail: state => state.c2p.email,
      checkoutProvider: state => state.c2p.checkoutProvider,
      lang: state => state.lang.current
    }),
    ...mapGetters({
      c2pUserProfile: 'getC2PProfile'
    }),
    isC2pUserLogged: function () {
      return this.c2pUserProfile !== null && typeof this.c2pUserProfile === 'object'
    },
    countryCodesList: function () {
      return COUNTRY_CODES
    },
    countryList: function () {
      return COUNTRY_CODES.map(item => item.name)
    },
    isCreditCardMastercard: function () {
      return this.checkoutProvider === CARD_ISSUERS.mastercard
    },
    isCreditCardVisa: function () {
      return this.checkoutProvider === CARD_ISSUERS.visa
    },
    c2pVisaTermsUrl: function () {
      const locale = LANGUAGES_CODE_LIST[this.lang]
      return C2P_VISA_TERMS_URL.replace('{locale}', locale)
    },
    c2pVisaPrivacyUrl: function () {
      const locale = LANGUAGES_CODE_LIST[this.lang]
      return C2P_VISA_PRIVACY_URL.replace('{locale}', locale)
    },
    c2pVisaCookieUrl: function () {
      const locale = LANGUAGES_CODE_LIST[this.lang]
      return C2P_VISA_COOKIE_URL.replace('{locale}', locale)
    }
  },
  methods: {
    ...mapMutations([
      'SET_C2P_PROCESS_RESET',
      'SET_C2P_PROCESSING',
      'SET_C2P_EMAIL',
      'SET_C2P_VIEW',
      'SET_C2P_CONSUMER_EMAIL',
      'SET_C2P_CONSUMER_FIRSTNAME',
      'SET_C2P_CONSUMER_LASTNAME',
      'SET_C2P_CONSUMER_PHONE_COUNTRYCODE',
      'SET_C2P_CONSUMER_PHONE_PHONENUMBER',
      'SET_C2P_REMEMBER_ME'
    ]),
    ...mapActions([]),
    isEmailDisabled () {
      return this.isC2pUserLogged && this.c2pEmail !== null
    },
    goBack () {
      this.SET_C2P_PROCESS_RESET()
      this.SET_C2P_VIEW(CLICK2PAY_VIEW.notRecognized)
      this.$emit('cancelClick2PayProcess')
    },
    isFormInvalid () {
      if (this.form) {
        return this.form.invalid
      }
      return false
    },
    setCountryCode () {
      if (!this.formData.country) {
        return
      }
      const countryCodeItem = COUNTRY_CODES.find(item => item.name === this.formData.country)
      if (!countryCodeItem) {
        return
      }
      this.formData.countryCode = countryCodeItem.code
    },
    submit () {
      if (this.isFormInvalid()) {
        return false
      }
      this.SET_C2P_PROCESSING(true)
      this.SET_C2P_EMAIL(this.formData.email)
      this.SET_C2P_CONSUMER_EMAIL(this.formData.email)
      this.SET_C2P_CONSUMER_FIRSTNAME(this.formData.firstname)
      this.SET_C2P_CONSUMER_LASTNAME(this.formData.lastname)
      this.SET_C2P_CONSUMER_PHONE_COUNTRYCODE(this.formData.countryCode)
      this.SET_C2P_CONSUMER_PHONE_PHONENUMBER(this.formData.phone)
      this.SET_C2P_REMEMBER_ME(this.formData.trusted)
      this.SET_C2P_VIEW(CLICK2PAY_VIEW.cardSelected)
      this.$emit('customerFormSubmited')
    }
  },
  mounted () {
    if (this.c2pEmail) {
      this.formData.email = this.c2pEmail
    } else {
      this.formData.email = this.customerEmail
    }
    this.formData.countryCode = '48'
    this.formData.country = 'Polska'
    if (this.$refs['email']) {
      this.$refs['email'].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/variables.scss";
@import "./src/base/assets/scss/mixins.scss";
@import "./src/base/assets/scss/components/PaywallForm.scss";

.subheader {
  font-family: "Lato Bold";
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.userdata {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include sm {
    flex-direction: row;
  }
}

.c2p-customer-phone {
  display: flex;
  flex-direction: row;
  gap: 20px;

  .countryCode {
    width: 124px;
  }

  .phoneNumber {
    width: 100%;
  }
}

.submit-wrapper {
  padding-top: 10px;

  .c2p-submit {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button.btn.btn-plain {
      font-size: 12px;
      text-transform: unset;
    }
  }
}

</style>
