<template>
  <div>
    <div class="c2p-login" v-if="!isClick2PayProcessActive && !isClick2PayViewLoginForm">
      <button @click="goToClick2PayLoginView">
        <span>{{ $t('paywall.form.click2pay.profile.header') }}</span>
        <img src="/web/img/click2pay/mastercard.svg" alt="">
      </button>
    </div>
    <div class="c2p-profile" v-if="isClick2PayProcessActive">
      <span>{{ getUserEmailAddress() }}</span>
      <button type="button" @click="goToClick2PayLoginForm" class="btn btn-plain" v-if="!isClick2PayViewLoginForm">
        {{ $t('paywall.form.click2pay.profile.buttonChangeEmail') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { CLICK2PAY_VIEW, C2P_MASTERCARD_IDENTITY_TYPE, CLICK2PAY_ORGANIZATION } from '@/base/const/click2pay.const'

export default {
  name: 'ClickToPayProfile',
  computed: {
    ...mapState({
      click2PayProcessActive: state => state.c2p.isProcessActive,
      click2PayView: state => state.c2p.view,
      identityValidationProvider: state => state.c2p.identityValidationProvider
    }),
    ...mapGetters({
      profile: 'getC2PProfile',
      identityValidation: 'getC2PIdentityValidation'
    }),
    isClick2PayProcessActive: function () {
      return this.click2PayProcessActive
    },
    isClick2PayViewLoginForm: function () {
      return this.click2PayView === CLICK2PAY_VIEW.loginForm
    }
  },
  methods: {
    ...mapMutations([
      'SET_C2P_VIEW'
    ]),
    getUserEmailAddress () {
      if (this.profile) {
        return this.profile.maskedConsumer.maskedEmailAddress
      }
      if (this.identityValidation) {
        if (this.identityValidationProvider === CLICK2PAY_ORGANIZATION.mastercard) {
          return this.identityValidation.supportedValidationChannels.find(channel => channel.identityType === C2P_MASTERCARD_IDENTITY_TYPE.email).maskedValidationChannel
        }
        return this.identityValidation.maskedValidationChannel
      }
      return ''
    },
    goToClick2PayLoginView () {
      this.SET_C2P_VIEW(CLICK2PAY_VIEW.loginForm)
    },
    goToClick2PayLoginForm () {
      this.SET_C2P_VIEW(CLICK2PAY_VIEW.loginForm)
    }
  }
}
</script>

<style lang="scss" scoped>
.c2p-login {
  margin-bottom: 30px;

  button {
    padding: 14px 40px;
    border: 1px solid var(--color-contrast-b2b);
    width: 100%;
    appearance: none;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      display: flex;
      font-size: 14px;
      color: var(--color-contrast-b2b);
      font-family: "Lato", Helvetica, Arial, sans-serif;
      margin-right: 32px;
    }
  }
}
.c2p-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  button {
    font-size: 12px;
    &.btn.btn-plain {
      text-transform: unset;
    }
  }
}
</style>
